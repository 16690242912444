import { useState } from "react";
import { useTheme } from "@emotion/react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Button, Menu, MenuItem } from "@mui/material";

export const SelectMenu = (props) => {
  const theme = useTheme();
  const { selectRow, setSelectRow, rowsSelects } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  /**
   * The handleClick function sets the anchorEl state to the current target of the event.
   * @param event - The event that triggered the function.
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  /**
   * The handleClose function is a function that sets the anchorEl to null.
   */
  const handleClose = () => {
    setAnchorEl(null);
  };
  /**
   * When a user clicks on a row, the value and textContent of the row are set to the state of the
   * selectRow object.
   * @param event - the event object
   */
  const handleSelectRow = (event) => {
    const { value, textContent } = event.target;
    setSelectRow({ page: 1, value: value, textContent: textContent });
    handleClose();
  };
  return (
    <>
      <Button
        size="large"
        sx={{ color: theme.palette.grey[900], mb: 1, mr: 1 }}
        color="secondary"
        endIcon={
          !Boolean(anchorEl) ? <ExpandMoreRoundedIcon /> : <ExpandLessIcon />
        }
        onClick={handleClick}
      >
        {selectRow.textContent}
      </Button>
      <Menu
        id="menu-user-list-style1"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        variant="selectedMenu"
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
      >
        {rowsSelects.map((row, idx) => (
          <MenuItem onClick={handleSelectRow} value={row.value} key={idx}>
            {" "}
            {row.textContent}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
