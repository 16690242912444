import { useRoutes } from 'react-router-dom';

// routes
/*import MainRoutes from './MainRoutes';
// import LoginRoutes from './LoginRoutes';*/
import AuthenticationRoutes from './AuthenticationRoutes';
import MainRoutes from "./MainRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    MainRoutes,
    AuthenticationRoutes,
    // MainRoutes
  ]);
}
