import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from 'ui-component/Logo';
// import { useTheme } from '@mui/material/styles';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
    // const theme = useTheme();
    return (
        <Link component={RouterLink} to={DASHBOARD_PATH}>
            <Logo />
        </Link>
    );
};

export default LogoSection;
