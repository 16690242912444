import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

import AuthGuard from "utils/route-guard/AuthGuard";
import Permiso from "views/configuracion/permiso";

// dashboard routing
const Dashboard = Loadable(lazy(() => import("views/dashboard")));

// oportunidades routing
const OportunidadIndicadores = Loadable(
  lazy(() => import("views/oportunidad/indicadores"))
);
const Oportunidad = Loadable(
  lazy(() => import("views/oportunidad/oportunidad"))
);
const Oportunidades = Loadable(
  lazy(() => import("views/oportunidad/oportunidades"))
);

// propuestas routing
const PropuestaIndicadores = Loadable(
  lazy(() => import("views/propuesta/propuesta"))
);
const Propuesta = Loadable(lazy(() => import("views/propuesta/propuesta")));
const Propuestas = Loadable(lazy(() => import("views/propuesta/propuestas")));

// propuestas routing
const Experiencias = Loadable(lazy(() => import("views/experiencia/experiencias")));
const Experiencia = Loadable(lazy(() => import("views/experiencia/experiencia")));

// configuracion routing
const Empresa = Loadable(lazy(() => import("views/configuracion/cliente")));
const Contacto = Loadable(lazy(() => import("views/configuracion/contacto")));
const Gerencia = Loadable(lazy(() => import("views/configuracion/gerencia")));
const TipoServicios = Loadable(
  lazy(() => import("views/configuracion/tipoServicios"))
);
const SectorEconomico = Loadable(
  lazy(() => import("views/configuracion/sectorEconomico"))
);
const Pais = Loadable(lazy(() => import("views/configuracion/pais")));

const OportunidadRoutes = [
  {
    path: "/oportunidad/indicadores",
    element: <OportunidadIndicadores />,
  },
  {
    path: "/oportunidades",
    element: <Oportunidades />,
  },
  {
    path: "/oportunidad/agregar",
    element: <Oportunidad />,
  },
  {
    path: "/oportunidad/editar/:id",
    element: <Oportunidad />,
  },
];

const PropuestaRoutes = [
  {
    path: "/propuesta/indicadores",
    element: <PropuestaIndicadores />,
  },
  {
    path: "/propuestas",
    element: <Propuestas />,
  },
  {
    path: "/propuesta/agregar",
    element: <Propuesta />,
  },
  {
    path: "/propuesta/editar/:id",
    element: <Propuesta />,
  },
];

const ExperienciaRoutes = [
  {
    path: "/experiencias",
    element: <Experiencias />,
  },
  {
    path: "/experiencia/agregar",
    element: <Experiencia />,
  },
  {
    path: "/experiencia/editar/:id",
    element: <Experiencia />,
  },
];

const Configuracion = [
  {
    path: "/configuracion/empresa/",
    element: <Empresa />,
  },
  {
    path: "/configuracion/contacto/",
    element: <Contacto />,
  },
  {
    path: "/configuracion/gerencia/",
    element: <Gerencia />,
  },
  {
    path: "/configuracion/servicios/",
    element: <TipoServicios />,
  },
  {
    path: "/configuracion/sectores-economicos/",
    element: <SectorEconomico />,
  },
  {
    path: "/configuracion/paises/",
    element: <Pais />,
  },
  {
    path: "/configuracion/permisos/",
    element: <Permiso />,
  },
];

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "",
      element: <Dashboard />,
    },
    ...OportunidadRoutes,
    ...PropuestaRoutes,
    ...ExperienciaRoutes,
    ...Configuracion,
  ],
};

export default MainRoutes;
