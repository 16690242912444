import { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";

import InputLabel from "ui-component/extended/Form/InputLabel";

import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import axiosServices from "utils/axios";
import { AutocompletePersonaAsync } from "ui-component/AutocompletePersonaAsync";

export default function PermisoDialog({
  refModule,
  open,
  tipoServicio,
  handleCloseDialog,
  setLoadContent,
  permisos,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();

  const [load, setLoad] = useState(false);

  const [usuario, setUsuario] = useState();

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      usuario: null,
      permisos: [],
    },
    validationSchema: Yup.object({
      usuario: Yup.object().required("Este campo es requerido"),
    }),
    onSubmit: async (values) => {
      // setLoad(true);
      const params = values;
      console.log("values", values.permisos);
      axiosServices.put(`permisos/usuario/${values.usuario.id}`, {
        permisos: values.permisos,
      });
    },
  });

  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const handleClose = () => {
    formik.resetForm();
    setLoad(false);
    handleCloseDialog();
  };

  return (
    <Dialog
      fullWidth={fullScreen}
      maxWidth={"lg"}
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      {open && (
        <>
          <DialogTitle id="form-dialog-title">
            {!tipoServicio ? "Agregar" : "Editar"} usuario
          </DialogTitle>
          <form onSubmit={formik.handleSubmit}>
            <DialogContent sx={{ ml: 2, mr: 1.5 }}>
              <Grid spacing={2} container>
                {/* <Grid item xs={12} lg={12}>
                  <InputLabel>Servicio</InputLabel>
                  <TextField
                    fullWidth
                    id="servicio"
                    placeholder="Ingresar servicio"
                    value={formik.values.servicio}
                    onChange={(e, v) => {
                      formik.setFieldValue("servicio", e.target.value);
                    }}
                    error={
                      formik.touched.servicio && Boolean(formik.errors.servicio)
                    }
                  />
                  {formik.touched.servicio && formik.errors.servicio && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {" "}
                      {formik.errors.servicio}{" "}
                    </FormHelperText>
                  )}
                </Grid> */}
                <AutocompletePersonaAsync
                  id="usuario"
                  label={"Usuario*"}
                  lg={12}
                  // value={usuario}
                  // setValue={(value) => setUsuario(value)}
                  value={formik.values.usuario}
                  setValue={
                    (value) => {
                      console.log("value", value);
                      formik.setFieldValue("usuario", value);
                    }
                    // handleChangeFormik("usuario", value)
                  }
                  touched={formik.touched.usuario}
                  errors={formik.errors.usuario}
                  errorsMsg={formik.errors.usuario}
                />
                {/* <Grid item xs={12} lg={12}>
                  <InputLabel>Acrónimo</InputLabel>
                  <TextField
                    fullWidth
                    id="acronimo"
                    placeholder="Ingresar acrónimo"
                    value={formik.values.acronimo}
                    onChange={(e) =>
                      formik.setFieldValue(
                        "acronimo",
                        e.target.value.toUpperCase()
                      )
                    }
                    error={
                      formik.touched.acronimo && Boolean(formik.errors.acronimo)
                    }
                  />
                  {formik.touched.acronimo && formik.errors.acronimo && (
                    <FormHelperText
                      error
                      id="standard-weight-helper-text-email-login"
                    >
                      {" "}
                      {formik.errors.acronimo}{" "}
                    </FormHelperText>
                  )}
                </Grid> */}
                {permisos && (
                  <Grid item xs={12} lg={12} sx={{ mt: 2 }}>
                    <TableContainer>
                      <Table
                        sx={{ minWidth: 650, pl: 0 }}
                        aria-label="simple table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ pl: 0 }}>Permiso</TableCell>
                            <TableCell align="right">Habilitar</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {permisos.map((row, idx) => (
                            <TableRow
                              key={idx}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell sx={{ pl: 0 }}>{row.nombre}</TableCell>
                              <TableCell align="right">
                                <Checkbox
                                  onChange={(_, value) => {
                                    value
                                      ? formik.setFieldValue("permisos", [
                                          ...formik.values.permisos,
                                          row.id,
                                        ])
                                      : formik.setFieldValue("permisos", [
                                          ...formik.values.permisos.filter(
                                            (el) => el !== row.id
                                          ),
                                        ]);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <DialogActions sx={{ pr: 4.5 }}>
              <Button
                onClick={handleClose}
                color="secondary"
                size="medium"
                variant="contained"
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                size="medium"
                type="submit"
                sx={{ width: "82px", height: "36.5px" }}
              >
                {!load ? (
                  !tipoServicio ? (
                    "Agregar"
                  ) : (
                    "Guardar"
                  )
                ) : (
                  <CircularProgress color="inherit" size={20} />
                )}
              </Button>
            </DialogActions>
          </form>
        </>
      )}
    </Dialog>
  );
}
