import PropTypes from "prop-types";
import { createContext, useEffect, useReducer } from "react";

// third-party
import firebase from "firebase/compat/app";
import "firebase/compat/auth";

// action - state management
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";
import axiosServices from "utils/axios";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// firebase initialize
if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

// const
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          console.log("user firebase", user);
          axiosServices.get(`/personas/uid/${user.uid}`).then(({ data }) => {
            const { _id, gerencia, perfil, permisos } = data;
            dispatch({
              type: LOGIN,
              payload: {
                isLoggedIn: true,
                user: {
                  id: user.uid,
                  email: user.email,
                  name: user.displayName || "John Doe",
                  photoURL: user.photoURL,
                  _id,
                  gerencia: gerencia._id,
                  perfil,
                  permisos,
                },
              },
            });
          });
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const firebaseGoogleSignIn = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const logout = () => firebase.auth().signOut();

  return (
    <FirebaseContext.Provider
      value={{
        ...state,
        login: () => {},
        firebaseGoogleSignIn,
        logout,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

FirebaseProvider.propTypes = {
  children: PropTypes.node,
};

export default FirebaseContext;
