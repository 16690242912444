import { useEffect, useState } from "react";
// material-ui
import {
  Backdrop,
  CardContent,
  CircularProgress,
  Dialog,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Pagination,
  Box,
  Checkbox,
} from "@mui/material";
import MainCard from "ui-component/cards/MainCard";
import { gridSpacing } from "store/constant";
import React from "react";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import { Delete } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddTwoTone";
import Chip from "ui-component/extended/Chip";
import { SelectMenu } from "ui-component/SelectMenu";
import TocIcon from "@mui/icons-material/Toc";

const TableConfig = ({
  Data,
  setData,
  headers,
  loadContent,
  setLoadContent,
  handleOpenDialog,
  totalPages,
  getPage,
  getLimit,
  getFind,
  handleSubmitDel,
  loadDel,
  handleCloseDel,
  isContacto,
  handleOportunidadBitacora,
  msgDel = (item) => `Delete item ${item?.id}`,
  disableAdd = false,
  rows = {
    value: 5,
    textContent: "5 filas",
  },
}) => {
  const [openDelEmpresa, setOpenDelEmpresa] = useState(false);
  const [item, setItem] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(rows);
  const [rowsMenu] = useState([
    { value: 5, textContent: "5 filas" },
    { value: 10, textContent: "10 filas" },
    { value: 20, textContent: "20 filas" },
    { value: 50, textContent: "50 filas" },
    { value: 0, textContent: "Todos" },
  ]);
  const [find, setFind] = useState("");
  const [auxFind, setAuxFind] = useState("");

  useEffect(() => {
    Data.length === 0 && page !== 1 && setPage((v) => v - 1);
  }, [Data, page]);

  const handleChangeFind = (event) => {
    setAuxFind(event.target.value);
  };

  const handleSearch = () => {
    setFind(auxFind);
    getFind(auxFind);
    setLoadContent(true);
  };

  const handleChangePagination = (e, value) => {
    setLoadContent(true);
    setPage(value);
    getPage(value);
  };

  const onDeleteEmpresa = (empresa) => {
    setItem(empresa);
    setOpenDelEmpresa(true);
  };

  return (
    <>
      <MainCard content={false}>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeFind}
                placeholder="Buscar"
                onBlur={() => {
                  auxFind !== find && handleSearch();
                }}
                onKeyDown={(e) =>
                  e.key === "Enter" && auxFind !== find && handleSearch()
                }
                value={auxFind}
                size="small"
              />
            </Grid>
            {!disableAdd && (
              <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
                <Tooltip title="Agregar cliente o empresa">
                  <Fab
                    color="primary"
                    size="small"
                    sx={{
                      boxShadow: "none",
                      ml: 1,
                      width: 32,
                      height: 32,
                      minHeight: 32,
                    }}
                  >
                    <AddIcon
                      onClick={() => {
                        setItem(null);
                        handleOpenDialog();
                      }}
                      sx={{
                        width: "24px",
                        height: "16px",
                        // pt: 0
                      }}
                    />
                  </Fab>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </CardContent>
        {/* table */}
        {!loadContent ? (
          Data.length > 0 ? (
            <Grid container>
              <Grid item xs={12}>
                <TableContainer>
                  <Table sx={{ minWidth: 350 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        {headers.map((el, idx) => (
                          <TableCell
                            {...el.style}
                            key={`header-${idx}`}
                            sx={el.sx}
                          >
                            {el.title}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Data.map((el, idx) => (
                        <TableRow hover key={idx}>
                          {headers.map(
                            (header, index) =>
                              header.col && (
                                <TableCell
                                  {...header.style}
                                  sx={header.sx}
                                  key={`${el.id}-${index}`}
                                >
                                  {header.chip ? (
                                    el[header.col]?.label && (
                                      <Chip
                                        size="small"
                                        label={el[header.col]?.label}
                                        chipcolor={header.chipcolor}
                                        clickable
                                      />
                                    )
                                  ) : header.isCheckBox ? (
                                    <Checkbox
                                      color="secondary"
                                      checked={el[header.col]?.value}
                                      onChange={() => {
                                        setData(el);
                                      }}
                                    />
                                  ) : (
                                    <Typography
                                      {...header.typography}
                                      component="div"
                                      // variant="h6"
                                      sx={{
                                        color: "black",
                                      }}
                                    >
                                      {el[header.col]?.label}
                                    </Typography>
                                  )}
                                </TableCell>
                              )
                          )}
                          {headers.find((el) => el.title === "Acciones") && (
                            <TableCell align="center" sx={{ pr: 3 }}>
                              <Stack
                                direction="row"
                                justifyContent="center"
                                alignItems="center"
                              >
                                {isContacto && (
                                  <Tooltip
                                    placement="top"
                                    title="Oportunidades y bitacoras"
                                  >
                                    <IconButton
                                      color="secondary"
                                      // aria-label="delete"
                                      size="large"
                                      onClick={() => {
                                        handleOportunidadBitacora(el);
                                      }}
                                    >
                                      <TocIcon sx={{ fontSize: "1.1rem" }} />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                <Tooltip placement="top" title="Editar">
                                  <IconButton
                                    color="secondary"
                                    // aria-label="delete"
                                    size="large"
                                    onClick={() => {
                                      setItem(el);
                                      handleOpenDialog(el);
                                    }}
                                  >
                                    <EditTwoToneIcon
                                      sx={{ fontSize: "1.1rem" }}
                                    />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Eliminar">
                                  <IconButton
                                    color="error"
                                    size="large"
                                    onClick={() => onDeleteEmpresa(el)}
                                  >
                                    <Delete sx={{ fontSize: "1.1rem" }} />
                                  </IconButton>
                                </Tooltip>
                              </Stack>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  pt: 2,
                }}
              >
                <Grid
                  container
                  justifyContent={
                    limit.value !== 0 ? "space-between" : "flex-end"
                  }
                  spacing={gridSpacing}
                >
                  {limit.value !== 0 && (
                    <Grid item>
                      <Pagination
                        color="primary"
                        page={page}
                        count={totalPages}
                        onChange={handleChangePagination}
                      />
                    </Grid>
                  )}
                  <Grid item>
                    <SelectMenu
                      selectRow={limit}
                      setSelectRow={(value) => {
                        setPage(1);
                        setLimit(value);
                        getLimit(value);
                      }}
                      rowsSelects={rowsMenu}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          ) : (
            <Box sx={{ pl: 3, pb: 2 }}>Sin resultados</Box>
          )
        ) : (
          <Backdrop
            sx={{
              color: "#fff",
              backgroundColor: "#fff",
              zIndex: (theme) => theme.zIndex.appBar - 1,
              position: "relative",
              height: "58vh",
              justifyContent: "center",
              alignItems: "center",
            }}
            open={loadContent}
          >
            <CircularProgress color="primary" />
          </Backdrop>
        )}
      </MainCard>
      <Dialog
        open={openDelEmpresa}
        onClose={() => handleCloseDel(setOpenDelEmpresa)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Eliminar</DialogTitle>
        <DialogContent>{msgDel(item)}</DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDel(setOpenDelEmpresa)} autoFocus>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              handleSubmitDel(item, setOpenDelEmpresa);
            }}
            color="error"
            sx={{ width: "70.65px", height: "24.5px" }}
          >
            {!loadDel ? (
              "Confirmar"
            ) : (
              <CircularProgress color="primary" size={14} />
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TableConfig;
