// material-ui
import { Grid } from "@mui/material";

// project imports
import { useEffect, useState } from "react";
import { gridSpacing } from "store/constant";
import React from "react";
import PermisoDialog from "./permisoDialog";
import axiosServices from "utils/axios";
import { useDispatch } from "store";
import { openSnackbar } from "store/slices/snackbar";
import TableConfig from "ui-component/TableConf";

// table data
function tableForm(servicio, acronimo) {
  return {
    nombre: { label: servicio },
    personas: { label: acronimo },
  };
}

// ==============================|| TABLE - BASIC ||============================== //

export default function Permiso() {
  const refModule = "permisos";
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [loadContent, setLoadContent] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [loadDel, setLoadDel] = useState(false);
  const [tipoServicio, setTipoServicio] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState({
    value: 5,
    textContent: "5 filas",
  });
  const [totalPages, setTotalPages] = useState(1);
  const [find, setFind] = useState("");
  const [permisos, setPermisos] = useState([]);

  const showSnackBar = (msg, variant, alert) => {
    dispatch(
      openSnackbar({
        open: true,
        message: msg,
        variant: variant,
        alert: alert,
        close: false,
      })
    );
  };

  useEffect(() => {
    const isPost = limit.value !== 0 || find !== "";
    const params =
      isPost && limit.value !== 0
        ? {
            page: page,
            limit: limit.value,
            filter: find,
          }
        : { filter: find, pagination: false };
    function fetchDataPost() {
      axiosServices
        .post(`${refModule}/pagination`, params)
        .then(({ data }) => {
          const { docs, totalPages } = data;
          setTotalPages(totalPages);
          const permisos = data.map((el) => {
            return {
              id: el.id,
              nombre: el.nombre,
            };
          });
          setPermisos(permisos);
          setRows(permisos);
        })
        .catch((err) => {
          console.log("Err", err);
        })
        .finally(() => setLoadContent(false));
    }
    fetchDataPost();
  }, [loadContent, page, find, limit]);

  useEffect(() => {
    rows.length === 0 && page !== 1 && setPage((v) => v - 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  const handleOpenDialog = (el) => {
    setTipoServicio(el);
    setOpenDialog(true);
  };

  const handleCloseDel = (setOpenDelEmpresa) => {
    setOpenDelEmpresa(false);
  };

  const handleSubmitDel = async (item, setOpenDelEmpresa) => {
    setLoadDel(true);
    axiosServices
      .delete(`${refModule}/${item.id}`)
      .then((res) => {
        showSnackBar("Servicio eliminado con exito", "alert", {
          color: "success",
        });
      })
      .catch((err) => {
        showSnackBar(err.msg, "alert", {
          severity: "error",
          color: "error",
        });
      })
      .finally(() => {
        handleCloseDel(setOpenDelEmpresa);
        setTipoServicio(null);
        setLoadDel(false);
        setLoadContent(true);
      });
  };

  const getPage = (value) => {
    setPage(value);
  };

  const getLimit = (value) => {
    setLimit(value);
  };

  const getFind = (value) => {
    setFind(value);
  };

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <TableConfig
          Data={rows}
          headers={[
            {
              title: "Nombre",
              style: {
                align: "left",
              },
              sx: {
                paddingLeft: "26px",
              },
              typography: {
                align: "left",
                variant: "h6",
              },
              col: "nombre",
            },
            {
              title: "Acciones",
              style: {
                align: "center",
                width: "263.775px",
              },
              col: false,
            },
          ]}
          loadContent={loadContent}
          setLoadContent={setLoadContent}
          handleOpenDialog={handleOpenDialog}
          handleSubmitDel={handleSubmitDel}
          totalPages={totalPages}
          getPage={getPage}
          getLimit={getLimit}
          getFind={getFind}
          loadDel={loadDel}
          handleCloseDel={handleCloseDel}
          msgDel={(item) =>
            `¿Está seguro de que desea eliminar el servicio ${item?.servicio.label} ?`
          }
        ></TableConfig>
      </Grid>
      <PermisoDialog
        refModule={refModule}
        tipoServicio={tipoServicio}
        open={openDialog}
        handleCloseDialog={() => {
          setOpenDialog(false);
        }}
        setLoadContent={setLoadContent}
        permisos={permisos}
      />
    </Grid>
  );
}
