import { memo } from "react";

// material-ui
import { Typography } from "@mui/material";

// project imports
import NavGroup from "./NavGroup";
import menuItem from "menu-items";

import useAuth from "hooks/useAuth";

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
  const { user } = useAuth();
  const filterItems = menuItem.items[0].children.filter((item) => {
    if (user?.permisos.some((el) => el.nombre === item.id)) {
      return item;
    }
  });

  //se deja la vista dashboard para mostrar por defecto
  menuItem.items[0].children = [menuItem.items[0].children[0], ...filterItems];

  const navItems = menuItem.items.map((item) => {
    switch (item.type) {
      case "group":
        return <NavGroup key={item.id} item={item} />;
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
