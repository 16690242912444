import ThemeCustomization from "./themes";

import Locales from "./ui-component/Locales";
import NavigationScroll from "./layout/NavigationScroll";
import Snackbar from "ui-component/extended/Snackbar";
import Routes from "routes";
import RTLLayout from "./ui-component/RTLLayout";

import { FirebaseProvider as AuthProvider } from "contexts/FirebaseContext";

const App = () => {
  return (
    <ThemeCustomization>
      <RTLLayout>
        <Locales>
          <NavigationScroll>
            <AuthProvider>
              <>
                <Routes />
                <Snackbar />
              </>
            </AuthProvider>
          </NavigationScroll>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
};

export default App;
