import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import LoadPage from "views/authentication/page";

const getQueryVariable = function (variable) {
  const query = window.location.search.substring(1);
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split("=");
    if (pair[0] === variable) {
      return pair[1];
    }
  }
  return false;
};


// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ props, children }) => {
  const { isLoggedIn, user } = useAuth();
  const navigate = useNavigate();
  const [load, setLoad] = useState(true);

  useEffect(() => {
    let currenToken = getQueryVariable("csrfToken");
    if (!currenToken) {
      currenToken = localStorage.getItem("csrfToken");
    }
    if (!isLoggedIn && (currenToken === "null" || !currenToken)) {
      setLoad(false);
      navigate("/login");
    }
    if (!currenToken && user) {
      navigate("/");
    }
    if (user && currenToken) {
      setLoad(false);
    }
    if (window.location.href.includes("csrfToken")) {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return !load ? children : <LoadPage></LoadPage>;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default AuthGuard;
